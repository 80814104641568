// Component generated with util/vox-create-component.js
import React from 'react';
import uniqid from 'uniqid';
import { AssetWrapper } from './Asset.styled';
import { AssetProps } from './Asset.types';
import { VoxCardAsset } from '@vox-capital/vox-ui-package';
import { useTranslation } from 'react-i18next';
import { getImageDownloadLink } from '../../service/api';
import Loader from '../Loader/Loader';

export const Asset: React.FC<AssetProps> = ({ asset, ...props }) => {
  // States
  const [loading, setLoading] = React.useState<boolean>(false);
  const [imageUrl, setImageUrl] = React.useState<string>('');

  // Translations
  const [c] = useTranslation('common');

  // Effects
  React.useEffect(() => {
    if (asset.image && asset.image !== '') {
      setLoading(true);
      // TODO: Datadog, add track here
      const firstImage = asset.image;
      (async () => {
        const imageDownloadedUrl = await getImageDownloadLink(firstImage);
        if (imageDownloadedUrl.fileUrl)
          setImageUrl(imageDownloadedUrl.fileUrl || '');
      })().finally(() => {
        setLoading(false);
      });
    }
  }, [asset.image]);

  return (
    <AssetWrapper {...props} className='relative'>
      {loading && (
        <div className='absolute z-10 left-0 right-0 top-0 bottom-0 bg-darkBlue-300 bg-opacity-30 flex items-center justify-center'>
          <Loader message='' />
        </div>
      )}
      <VoxCardAsset
        name={uniqid()}
        description={asset.description}
        asset={asset.name}
        image={imageUrl}
        ods={[]}
        statement=''
        aspiration=''
        tematic=''
      />
    </AssetWrapper>
  );
};

export default Asset;
