import React from 'react';
import { VoxDataFund, VoxLayoutContainer } from '@vox-capital/vox-ui-package';
import { LegacyFundsProps } from '../Types/LegacyData';
import { useTranslation } from 'react-i18next';
import uniqid from 'uniqid';

const AppLegacyFunds: React.FC<
  LegacyFundsProps & React.HTMLAttributes<HTMLDivElement>
> = ({ funds, ...args }) => {
  const [l] = useTranslation('legacy');
  if (!funds.length) return <></>;
  return (
    <div
      {...args}
      id='funds-container'
      className='bg-gray-200 pt-16 pb-16 flex w-full items-center justify-center'
    >
      <VoxLayoutContainer>
        <div id='title' className='w-8/12 md:w-6/12'>
          <span className='text-2xl lg:text-4xl font-bold text-darkBlue-300'>
            {l('legacy.distribution.title')}
          </span>
        </div>
        <hr className='h-8' />
        <div id='funds-list' className='flex flex-col gap-2'>
          {funds.map(e => (
            <VoxDataFund
              key={uniqid()}
              {...e}
              tags={[...e.tags.map(tag => l(`legacy.teses.${tag}.name`))]}
            />
          ))}
        </div>
      </VoxLayoutContainer>
    </div>
  );
};

export default AppLegacyFunds;
