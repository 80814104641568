/* eslint-disable react-hooks/exhaustive-deps */
// Component generated with util/vox-create-component.js
import React from 'react';
import * as R from 'ramda';
import { AppLegacyProps } from './AppLegacy.types';
import uniqid from 'uniqid';
import { useTranslation } from 'react-i18next';
import LegacyFunds from './Components/LegacyFunds';
import LegacyTeses from './Components/LegacyTeses';
import { DataFundProps } from '@vox-capital/vox-ui-package/component/DataFund/DataFund.types';
import LegacyData from './Components/LegacyData';
import { LegacyCompanyProps, LegacyTeseProps } from './Types/LegacyData';
import LegacyFinancial from './Components/LegacyFinancial';
import { dataDogTags, trackError, trackView } from '../../analytics';
import {
  useGetPortafolioLazyQuery,
  Position,
  Kaleidoscope,
  Asset,
  Thesis,
} from './../../graphql/types.d';
import AuthContext from '../../context/AuthContext';
import {
  formatImpactData,
  formatPositionsPercent,
  getThesisName,
  getThesisNameByAssets,
} from './../../helpers';
import AlertContext from '../../context/AlertContext';
import { EAlertType } from '@vox-capital/vox-ui-package';
import { AppEmptyBlock, AppLoadingBlock } from '../../component';

export const AppLegacy: React.FC<AppLegacyProps> = props => {
  // Analytics
  trackView(dataDogTags.views.privateHome, dataDogTags.views.privateLegacy);
  // Hooks
  const [l] = useTranslation('legacy');
  const [c] = useTranslation('common');
  const [e] = useTranslation('errors');
  // Context
  const { showAlert, closeAlert } = React.useContext(AlertContext);
  const { user, currentPosition, clearSession } = React.useContext(AuthContext);
  // Navigation
  // const history = useHistory();
  // States
  const [localLoad, setLocalLoad] = React.useState(true);
  const [companies, setCompanies] = React.useState<LegacyCompanyProps[]>([]);
  const [thesis, setThesis] = React.useState<LegacyTeseProps[]>([]);
  const [histories, setHistories] = React.useState<Kaleidoscope[]>([]);
  const [positions, setPositions] = React.useState<DataFundProps[]>([]);
  // Graphql hooks
  const [getPortfolio, { data, error, loading, refetch }] =
    useGetPortafolioLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  // Get Portfolio
  React.useEffect(() => {
    if (user && user.personId) {
      getPortfolio({
        variables: {
          personId: `${user.personId}`,
        },
      });
    }
  }, [getPortfolio, user]);
  // Fill legacy historias
  React.useEffect(() => {
    if (error) {
      const gqlError = error.graphQLErrors[0];
      const errorCode = gqlError ? gqlError.extensions.code : true;
      if (errorCode && errorCode.toString() === 'UNAUTHENTICATED') {
        trackError(dataDogTags.fetch.errors.unauthentication, { gqlError });
        showAlert({
          title: e('errors.auth.SessionExpired.title'),
          message: e('errors.auth.SessionExpired.message'),
          type: EAlertType.error,
          primaryAlertAction: () => {
            clearSession();
            closeAlert();
          },
        });
      } else {
        trackError(dataDogTags.fetch.errors.graphql, { gqlError });
        showAlert({
          title: c('errors.common.wrong.title'),
          message: c('errors.common.wrong.message'),
          type: EAlertType.error,
        });
      }
    } else if (data && data.portfolio) {
      const dataPosition: any[] = data.portfolio.positions || [];
      const assetsList: Asset[] = R.reduce(
        (acc: any[], next: Position | undefined) => {
          const assets: any[] = next!.fund?.assets ?? [];
          return [...acc, ...assets];
        },
        [],
        dataPosition
      );
      const thesisNames = getThesisNameByAssets(assetsList);
      const thesisList: LegacyTeseProps[] = R.reduce(
        (acc: LegacyTeseProps[], name: string) => {
          return [
            ...acc,
            {
              id: name,
              name: l(`legacy.teses.${name}.name`),
              description: l(`legacy.teses.${name}.description`),
              image: l(`legacy.teses.${name}.image`),
            },
          ];
        },
        [],
        thesisNames
      );
      const companiesList: LegacyCompanyProps[] = assetsList.map(asset => ({
        id: uniqid(),
        name: asset.name || '',
        description: asset.description || '',
        image: asset.image || '',
        thesis: R.reduce(
          (previousThesi: string[], nextThesi: any) => {
            const thesisFilter = R.filter((thesi: Thesis) => {
              const name = getThesisName(nextThesi.name);
              return thesi.id === name;
            }, thesisList);
            const thesisId = R.reduce(
              (previousLegacy: string[], nextLegacy: LegacyTeseProps) => [
                ...previousLegacy,
                nextLegacy.id,
              ],
              [],
              thesisFilter
            );
            return [...previousThesi, ...thesisId];
          },
          [],
          asset.impacts?.thesisList || []
        ),
      }));
      const kaleidoscopeList: Kaleidoscope[] =
        formatImpactData(dataPosition) || [];
      const positionList = formatPositionsPercent(dataPosition);
      setThesis(thesisList);
      setPositions(positionList);
      setCompanies(companiesList);
      setHistories(kaleidoscopeList);
    }
    setTimeout(() => setLocalLoad(false), 1000);
  }, [data, error]);
  // Component
  if (loading || localLoad) return <AppLoadingBlock />;

  if (!data || !data.portfolio || error)
    return (
      <AppEmptyBlock
        refresh={() =>
          refetch
            ? refetch({
                personId: `${user?.personId}`,
              })
            : () => {}
        }
      />
    );

  return (
    <div {...props} className='flex flex-col w-full items-center'>
      <LegacyFunds funds={[...positions]} />
      {thesis.length > 0 ? (
        <LegacyTeses companies={companies} thesis={thesis} />
      ) : (
        <></>
      )}
      <LegacyData
        legacy={histories}
        quote={{
          title:
            'Com foco, transparência e seriedade conseguimos, com a VOX, aprender sobre a seleção de investimentos e métricas de avaliação de impacto',
          message:
            'Como alocador que recomenda o fundo para clientes, vemos a VOX como uma parceira.',
          owner: 'Gilberto César Filho - Sócio ESTGP',
          role: 'Alocador dos Fundos de Venture Capital VOX',
        }}
      />
      {/* 
        TODO: Adicionar fake data late.
        <LegacyNumber numbers={numbers} />
       */}
      <LegacyFinancial
        financial={{
          message: l('legacy.banner.title'),
          sensitive: false,
          profitability: {
            status: currentPosition.positive ? 'POSITIVE' : 'NEGATIVE',
            total: currentPosition.grossValue || '',
            gained: currentPosition.totalProfitability || '',
            percent: currentPosition.profitability || '',
          },
        }}
      />
    </div>
  );
};

export default AppLegacy;
