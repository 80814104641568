// Component generated with util/vox-create-component.js
import React from 'react';
import * as R from 'ramda';
import { AppDocumentsWrapper } from './AppDocuments.styled';
import { useTranslation } from 'react-i18next';
import {
  AppDocumentsProps,
  TFiles,
  TDocuments,
  FilesYear,
} from './AppDocuments.types';
import { Loading } from './../../ui';
import { AppEmptyBlock, AppLoadingBlock, UI } from './../../component';
import { TabProps } from '@vox-capital/vox-ui-package/ui/Tab/Tab.types';
import {
  getBrowserLang,
  getDocumentsTypes,
  getDocuments,
  getSectionsFiles,
} from './../../helpers';
import {
  useGetDocumentsLazyQuery,
  useGetVoxDocumentsLazyQuery,
} from './../../graphql/types.d';
import { AuthContext } from './../../context/AuthContext';
import { AlertContext } from './../../context/AlertContext';
import { downLoadDocumentFile } from './../../service/api';
import { AppContext } from '../../context/AppContext';
import moment from 'moment';
import uniqid from 'uniqid';
import {
  EAlertType,
  EButtonPreset,
  VoxButton,
  VoxIcon,
  VoxLayoutContainer,
  VoxText,
} from '@vox-capital/vox-ui-package';
import {
  Colors,
  EFontWeight,
  Icons,
  TextSizes,
} from '@vox-capital/vox-ui-package';
import {
  dataDogTags,
  trackAction,
  trackError,
  trackView,
} from '../../analytics';

const lang = getBrowserLang();
// Helpers
const groupFilesByYear = (files: TFiles[]) =>
  files.reduce((acc: any, next: TFiles) => {
    const dateFormat = moment(next.dateCreation, 'DD/MM/YYYY').locale(lang);
    const dateCreation = `${dateFormat.format('DD')} ${dateFormat.format(
      `MMM`
    )}`;
    const year = dateFormat.year();
    const objYear = acc[year];
    if (objYear) {
      const r = { ...acc };
      r[year] = [...objYear, { ...next, dateCreation }];
      return r;
    } else {
      return {
        ...acc,
        [year]: [{ ...next, dateCreation }],
      };
    }
  }, {});

export const AppDocuments: React.FC<AppDocumentsProps> = ({ ...args }) => {
  trackView(
    dataDogTags.views.privateDocuments,
    dataDogTags.views.privateDocuments
  );
  // Context
  const { state } = React.useContext(AppContext);
  // languages
  const [t] = useTranslation('documents');
  const [c] = useTranslation('common');
  const { user } = React.useContext(AuthContext);
  const { showAlert } = React.useContext(AlertContext);
  // States
  const [localLoad, setLocalLoad] = React.useState(true);
  const [downloading, setDownloading] = React.useState(false);
  const [currentTab, setCurrentTab] = React.useState<any>(null);
  const [sections, setSections] = React.useState<TabProps[]>([]);
  const [documents, setDocuments] = React.useState<TDocuments[]>([]);
  const [allDocuments, setAllDocuments] = React.useState<TDocuments[]>([]);
  const [files, setFiles] = React.useState<TFiles[]>([]);
  const [newFiles, setNewFiles] = React.useState<FilesYear>({});
  const [years, setYears] = React.useState<string[]>([]);
  const [currentYear, setCurrentYear] = React.useState<string>();
  const [currentDocument, setCurrentDocument] = React.useState<
    TDocuments | undefined
  >();
  const [getDocumentsData, { data, loading, error, refetch, networkStatus }] =
    useGetDocumentsLazyQuery({
      notifyOnNetworkStatusChange: false,
    });
  const [getVoxDocumentsData, { data: voxData, loading: voxLoading }] =
    useGetVoxDocumentsLazyQuery({
      notifyOnNetworkStatusChange: false,
    });
  // Effects
  React.useEffect(() => {
    if (!state || R.isEmpty(state.allPositions)) {
      getVoxDocumentsData({
        variables: {
          path: 'common',
        },
      });
      getDocumentsData({
        variables: {
          personId: `${user?.personId}`,
        },
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDocumentsData, getVoxDocumentsData]);
  React.useEffect(() => {
    if ((state && !R.isEmpty(state.allPositions)) || data) {
      const dataPositions = data?.portfolio?.positions;
      const dataDocuments = voxData?.documents || state?.allDocuments;
      const formatSections = getSectionsFiles(
        dataPositions || state?.allPositions
      );
      const formatDocTypes = getDocumentsTypes(
        dataPositions || state?.allPositions
      );
      const formatDocuments = getDocuments(
        dataPositions || state?.allPositions,
        formatDocTypes
      ).concat(
        dataDocuments && !R.isEmpty(dataDocuments)
          ? [
              {
                id: 'vox-documents',
                section: 'vox-documents',
                name: 'Documentos Vox',
                files: [
                  ...(dataDocuments || []).map((d: any) => ({
                    ...d,
                    dateCreation: d?.date,
                    name: d.name,
                  })),
                ],
              },
            ]
          : []
      );
      setSections(formatSections);
      setAllDocuments(formatDocuments);
      setCurrentTab(formatSections[0].name);
      setTimeout(() => setLocalLoad(false), 1000);
    }
    return () => {};
  }, [state, data, voxData]);
  React.useEffect(() => {
    if (currentTab && !R.isEmpty(allDocuments)) {
      const newDocs = allDocuments.filter(tdoc => tdoc.section === currentTab);
      const currentDoc = newDocs[0];
      setCurrentDocument(currentDoc);
      setDocuments(newDocs);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);
  React.useEffect(() => {
    if (currentDocument) {
      const filesByYear = groupFilesByYear(currentDocument.files);
      const documentYears = Object.keys(filesByYear).sort().reverse();
      setYears(documentYears);
      setCurrentYear(documentYears[0]);
      setNewFiles(filesByYear);
    }
    return () => {};
  }, [currentDocument]);
  React.useEffect(() => {
    if (newFiles && currentYear) setFiles(newFiles[currentYear]);
    else setFiles([]);
    return () => {};
  }, [currentYear, newFiles]);
  // Handlers
  const menuItemClick = (item: string) => {
    const newDocument = allDocuments.find(
      tdoc => tdoc.name === item && tdoc.section === currentTab
    );
    trackAction(dataDogTags.actions.documents.changeDocument, newDocument);
    setCurrentDocument(newDocument);
  };
  const tabItemClick = (tab: string) => {
    trackAction(dataDogTags.actions.documents.changeTag, { tab });
    setCurrentTab(tab === currentTab ? null : tab);
  };
  const printStatement = async (file: any) => {
    setDownloading(true);
    trackAction(dataDogTags.actions.documents.printDocument, file);
    const response = await downLoadDocumentFile(file);
    if (response && response.error) {
      trackError(dataDogTags.fetch.errors.printfile, { response });
      showAlert({
        type: EAlertType.error,
        title: t('documents.error.download.title'),
        message: t('documents.error.download.gettingUrl'),
      });
    }
    setDownloading(false);
  };
  if ((loading || voxLoading || localLoad) && networkStatus !== 3) {
    return <AppLoadingBlock />;
  }
  if (!allDocuments.length || error) {
    return (
      <AppEmptyBlock
        title={c('empty.documents.label')}
        message={c('empty.documents.description')}
        refresh={() =>
          refetch
            ? refetch({
                personId: `${user?.personId}`,
              })
            : {}
        }
      />
    );
  }
  return (
    <div className='flex flex-col w-full items-center py-16'>
      <VoxLayoutContainer>
        <AppDocumentsWrapper {...args}>
          {downloading && <Loading loadingMessage='Aguarde um momento' />}
          {/* mobile */}
          <div
            id='documents-mobile-view'
            className='flex  md:hidden flex-col gap-8'
            data-testid='vox-list-documents'
          >
            {sections.map(section => (
              <div key={uniqid()} className=''>
                <div
                  className={`flex h-16 bg-gray-100 px-8 justify-between items-center border-2 border-gray-200 rounded-tl-md rounded-tr-md ${
                    currentTab === section.name
                      ? ''
                      : 'rounded-bl-md rounded-br-md'
                  }`}
                  onClick={() => tabItemClick(section.name)}
                >
                  <VoxText
                    preset={TextSizes.bodyMd}
                    color={Colors.darkBlue}
                    weight={EFontWeight.medium}
                  >
                    {section.label}
                  </VoxText>
                  <VoxIcon
                    icon={
                      currentTab === section.name
                        ? Icons.expand_less
                        : Icons.expand_more
                    }
                  />
                </div>
                {currentTab === section.name && (
                  <div className='body px-4 border-b-2 border-r-2 border-l-2 border-gray-200 rounded-bl-md rounded-br-md'>
                    {documents.map(tdoc => {
                      if (!R.isEmpty(tdoc.files)) {
                        return (
                          <div key={uniqid()}>
                            <div className='py-4'>
                              <UI.VoxText
                                preset={TextSizes.bodyMd}
                                color={Colors.pink800}
                                weight={EFontWeight.bold}
                              >
                                {tdoc.name}
                              </UI.VoxText>
                            </div>
                            <div className='flex flex-col gap-2'>
                              {tdoc.files.map(doc => (
                                <UI.VoxDocumentItem
                                  key={uniqid()}
                                  icon={Icons.system_update_alt}
                                  onClick={() => printStatement(doc.id)}
                                  label={`${moment(
                                    doc.dateCreation,
                                    'DD/MM/YYYY'
                                  )
                                    .locale(lang)
                                    .format('LL')}`}
                                />
                              ))}
                            </div>
                            <br />
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                )}
              </div>
            ))}
          </div>
          {/* desktop */}
          <div
            id='document-desktop-view'
            className=' flex-col gap-8 hidden md:flex md:gap-16'
          >
            <div>
              <UI.VoxTabList onSelectTab={tabItemClick} tabs={sections} />
            </div>
            {currentDocument ? (
              <div className='flex flex-col lg:flex-row gap-4 lg:gap-16'>
                <div className='w-full lg:w-4/12 flex flex-col gap-2'>
                  <div className='mb-0 md:px-6'>
                    <UI.VoxText
                      preset={TextSizes.bodyMd}
                      color={Colors.gray500}
                      weight={EFontWeight.medium}
                    >
                      Lista de documentos disponiveis
                    </UI.VoxText>
                  </div>
                  {documents.map(tdoc => {
                    return (
                      <div
                        key={uniqid()}
                        className='py-2 md:px-6 cursor-pointer hover:bg-pink-100'
                      >
                        <VoxText
                          weight={EFontWeight.bold}
                          color={
                            currentDocument?.name === tdoc.name
                              ? Colors.pink800
                              : Colors.darkBlue
                          }
                          preset={TextSizes.heading5}
                          onClick={() => menuItemClick(tdoc.name)}
                        >
                          {tdoc.name}
                        </VoxText>
                      </div>
                    );
                  })}
                </div>
                <div className='w-full lg:w-8/12 bg-gray-100 py-12 px-8 rounded-md'>
                  {!R.isEmpty(files) && !R.isEmpty(years) ? (
                    <div className='flex flex-col gap-8'>
                      <div className='flex gap-4'>
                        {years.map(y => (
                          <VoxButton
                            key={uniqid()}
                            label={y}
                            preset={
                              currentYear === y
                                ? EButtonPreset.solid
                                : EButtonPreset.link
                            }
                            color={Colors.darkBlue}
                            onClick={() => setCurrentYear(y)}
                          />
                        ))}
                      </div>
                      <div className='flex flex-col gap-2'>
                        {files.map((file, keyFile) => (
                          <UI.VoxDocumentItem
                            key={`${currentTab}-${currentDocument?.name}-${file.id}-${keyFile}`}
                            label={file.dateCreation}
                            description={file.name}
                            icon={Icons.system_update_alt}
                            onClick={() => printStatement(file.id)}
                          />
                        ))}
                      </div>
                    </div>
                  ) : (
                    <UI.VoxText
                      data-testid='vox-empty-files-message'
                      preset={TextSizes.body}
                      color={Colors.gray400}
                    >
                      {t('documents.empty.files')}
                    </UI.VoxText>
                  )}
                </div>
              </div>
            ) : (
              <div className='flex items-center justify-center w-full bg-gray-100 py-24 px-8 rounded-md'>
                <div className='flex flex-col gap-2 items-center'>
                  <VoxText
                    weight={EFontWeight.bold}
                    color={Colors.darkBlue}
                    preset={TextSizes.heading5}
                  >
                    Não há documentos
                  </VoxText>
                  <VoxText
                    weight={EFontWeight.bold}
                    color={Colors.gray500}
                    preset={TextSizes.bodyMd}
                  >
                    Este fundo ainda nãodisponibilizou documentos
                  </VoxText>
                </div>
              </div>
            )}
          </div>
        </AppDocumentsWrapper>
      </VoxLayoutContainer>
    </div>
  );
};

export default AppDocuments;
