import {
  Colors,
  EFontWeight,
  TextSizes,
  VoxLayoutContainer,
  VoxText,
} from '@vox-capital/vox-ui-package';
import React from 'react';
import uniqid from 'uniqid';
import { Position } from '../../../graphql/types';
import { Disclaimer } from '../../../ui';
import { transFormCurreny, transFormNumber } from '../../../helpers';
import { AppEmptyBlock } from '../../../component';

export const HomePositions: React.FC<{
  positions: Position[];
  sesitive: boolean;
  refresh: () => void;
}> = ({ positions, sesitive, refresh }) => {
  if (!positions.length) return <AppEmptyBlock refresh={refresh} />;

  return (
    <div className='statements flex flex-col md:flex-row md:gap-12'>
      <div className='title flex flex-col gap-4 w-full md:w-4/12'>
        <VoxText
          preset={TextSizes.heading4}
          weight={EFontWeight.bold}
          color={Colors.darkBlue}
        >
          Distribuição da minha carteira
        </VoxText>
        <VoxText
          preset={TextSizes.bodyLg}
          weight={EFontWeight.bold}
          color={Colors.gray500}
        >
          Confira como o seu patrimônio está distribuído na VOX Capital e
          otimize suas oportunidades de crescimento.
        </VoxText>
      </div>
      <div
        className='items flex flex-col md:grid lg:grid-cols-2 gap-4 items-center align-middle justify-between py-8 w-full md:w-8/12'
        data-testid='vox-position-list'
      >
        {positions.map((pos: Position) => (
          <div
            className='position h-full bg-gray-100 border-2 border-gray-200 rounded-md flex flex-col gap-4 w-full'
            key={uniqid()}
          >
            <div className='fund md:h-128 pt-8 pb-2 px-6 bg-gray-200 flex items-end'>
              <VoxText
                preset={TextSizes.bodyMd}
                color={Colors.pink800}
                weight={EFontWeight.bold}
              >
                {pos.fund?.name || 'Fundo vox'}
              </VoxText>
            </div>
            <div className='flex flex-col gap-4 px-6 pb-4'>
              <div className='data flex flex-col gap-1'>
                <VoxText
                  preset={TextSizes.bodyMd}
                  color={Colors.gray500}
                  weight={EFontWeight.medium}
                >
                  Patrimônio no fundo
                </VoxText>
                <div className='flex gap-2 items-center'>
                  <VoxText
                    preset={TextSizes.bodyLg}
                    color={Colors.darkBlue}
                    weight={EFontWeight.bold}
                  >
                    {sesitive
                      ? `${transFormCurreny(pos.currentPositionValue || 0)}`
                      : '******,**'}
                  </VoxText>
                  <Disclaimer
                    children={
                      <div className='py-4 flex flex-col gap-2'>
                        <VoxText
                          preset={TextSizes.bodyMd}
                          weight={EFontWeight.medium}
                          color={Colors.darkBlue}
                        >
                          <b>Patrimônio:</b> Patrimônio bruto calculado a partir
                          da cota do fundo na data expressa acima, considerando
                          a valorização das cotas de acordo com o fim do
                          exercício social do fundo
                        </VoxText>
                        <span className='text-gray-500 text-sm'>
                          obs: cálculo realizado levando em consideração apenas
                          o valor já integralizado
                        </span>
                      </div>
                    }
                  />
                </div>
              </div>
              {pos.fund?.totalProfitability !== null && (
                <div className='data flex flex-col'>
                  <VoxText
                    preset={TextSizes.bodyMd}
                    color={Colors.gray500}
                    weight={EFontWeight.medium}
                  >
                    Rentabilidade do fundo
                  </VoxText>
                  <div className='flex gap-2 items-center'>
                    <VoxText
                      className='value'
                      preset={TextSizes.bodyLg}
                      color={Colors.darkBlue}
                      weight={EFontWeight.bold}
                    >
                      {sesitive
                        ? `${transFormCurreny(pos.totalProfitability)}`
                        : '******,**'}
                    </VoxText>
                    <Disclaimer
                      children={
                        <div className='py-4'>
                          <VoxText
                            preset={TextSizes.bodyMd}
                            weight={EFontWeight.medium}
                            color={Colors.darkBlue}
                          >
                            <b>Rentabilidade:</b> A cota do fundo é impactada
                            negativamente quando há evento de amortização de
                            cotas. Dessa forma, nos casos em que houve
                            amortização, a rentabilidade demonstrada neste
                            relatório é gerencial, uma vez que ela considera os
                            ganhos auferidos com as amortizações já pagas.
                          </VoxText>
                        </div>
                      }
                    />
                  </div>
                  <VoxText
                    className='value'
                    preset={TextSizes.bodyLg}
                    color={Colors.darkBlue}
                    weight={EFontWeight.bold}
                  >
                    {sesitive
                      ? `${transFormNumber(pos.profitability || 0)}%`
                      : '******,**'}
                  </VoxText>
                </div>
              )}
              {pos.subscriptionValue !== null && (
                <div className='flex flex-col'>
                  <VoxText
                    preset={TextSizes.bodyMd}
                    color={Colors.gray500}
                    weight={EFontWeight.medium}
                  >
                    Valor Subscrito
                  </VoxText>
                  <div className='flex gap-2 items-center'>
                    <VoxText
                      className='value'
                      preset={TextSizes.bodyLg}
                      color={Colors.darkBlue}
                      weight={EFontWeight.bold}
                    >
                      {sesitive
                        ? `${transFormCurreny(pos.subscriptionValue)}`
                        : '******,**'}
                    </VoxText>
                    <Disclaimer
                      children={
                        <div className='py-4 flex flex-col gap-2'>
                          <VoxText
                            preset={TextSizes.bodyMd}
                            weight={EFontWeight.medium}
                            color={Colors.darkBlue}
                          >
                            <b>Valor Subscrito:</b> Valor comprometido de
                            investimento ao fundo
                          </VoxText>
                          <VoxText
                            preset={TextSizes.bodyMd}
                            weight={EFontWeight.medium}
                            color={Colors.darkBlue}
                          >
                            <b>Valor Integralizado:</b> Valor já integralizado
                            através das chamadas de capital
                          </VoxText>
                        </div>
                      }
                    />
                  </div>
                  <VoxText
                    preset={TextSizes.bodyMd}
                    color={Colors.gray500}
                    weight={EFontWeight.medium}
                  >
                    {(() => {
                      if (!sesitive) {
                        return '******,**';
                      }
                      const percent = (
                        (100 * (pos.integralizationValue || 0)) /
                        (pos.subscriptionValue || 1)
                      ).toFixed(0);
                      if (!percent) {
                        return '-';
                      }
                      return `${percent}% `;
                    })()}
                    integralizado
                  </VoxText>
                  <VoxText
                    preset={TextSizes.bodyMd}
                    color={Colors.gray500}
                    weight={EFontWeight.medium}
                  >
                    {transFormCurreny(pos.integralizationValue)}
                  </VoxText>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePositions;
