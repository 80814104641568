import i18next from 'i18next';
import moment from 'moment';
import {
  TranslationCommon_EN,
  TranslationAuth_EN,
  TranslationDocuments_EN,
  TranslationDashboard_EN,
  TranslationContact_EN,
  TranslationStatements_EN,
  TranslationNavigation_EN,
  TranslationErrors_EN,
  TranslationLegacy_EN,
} from './../translations/en-us';
import {
  TranslationCommon_PT,
  TranslationAuth_PT,
  TranslationDocuments_PT,
  TranslationDashboard_PT,
  TranslationContact_PT,
  TranslationStatements_PT,
  TranslationNavigation_PT,
  TranslationErrors_PT,
  TranslationLegacy_PT,
} from './../translations/pt-br';
import { getBrowserLang } from './../helpers';

moment.locale('pt-br', {
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  monthsShort: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ],
});

i18next.init({
  fallbackLng: 'pt_br',
  interpolation: { escapeValue: false },
  lng: getBrowserLang(),
  resources: {
    en_us: {
      common: TranslationCommon_EN,
      auth: TranslationAuth_EN,
      documents: TranslationDocuments_EN,
      home: TranslationDashboard_EN,
      contact: TranslationContact_EN,
      statements: TranslationStatements_EN,
      navigation: TranslationNavigation_EN,
      errors: TranslationErrors_EN,
      legacy: TranslationLegacy_EN,
    },
    pt: {
      common: TranslationCommon_PT,
      auth: TranslationAuth_PT,
      documents: TranslationDocuments_PT,
      home: TranslationDashboard_PT,
      contact: TranslationContact_PT,
      statements: TranslationStatements_PT,
      navigation: TranslationNavigation_PT,
      errors: TranslationErrors_PT,
      legacy: TranslationLegacy_PT,
    },
    pt_br: {
      common: TranslationCommon_PT,
      auth: TranslationAuth_PT,
      documents: TranslationDocuments_PT,
      home: TranslationDashboard_PT,
      contact: TranslationContact_PT,
      statements: TranslationStatements_PT,
      navigation: TranslationNavigation_PT,
      errors: TranslationErrors_PT,
      legacy: TranslationLegacy_PT,
    },
  },
});

export { i18next };
