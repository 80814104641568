// Component generated with util/vox-create-component.js
import React from 'react';
import { AppLoadingBlockProps } from './AppLoadingBlock.types';
import { Loader } from '../../ui';
import { useTranslation } from 'react-i18next';

export const AppLoadingBlock: React.FC<AppLoadingBlockProps> = ({
  message,
}) => {
  const [c] = useTranslation('common');
  return (
    <div className='w-full flex items-center justify-center h-screen md:h-312'>
      <div data-testid='vox-data-loading'>
        <Loader message={message || c('loading.message')} />
      </div>
    </div>
  );
};

export default AppLoadingBlock;
