// Component generated with util/vox-create-component.js
import React from 'react';
import { BannerFinancialWrapper } from './BannerFinancial.styled';
import { BannerFinancialProps } from './BannerFinancial.types';
import {
  Colors,
  EFontWeight,
  TextSizes,
  VoxText,
} from '@vox-capital/vox-ui-package';

export const BannerFinancial: React.FC<BannerFinancialProps> = ({
  message,
  profitability,
  sensitive,
  ...args
}) => {
  return (
    <BannerFinancialWrapper {...args}>
      <div className='financial-data flex flex-col gap-2 justify-start items-start md:px-6'>
        <div className='flex flex-col md:flex-row-reverse w-full gap-2 flex-1 rounded-md items-center'>
          <div
            className='w-full md:w-6/12 lg:w-8/12 h-312 overflow-hidden '
            style={{
              backgroundPosition: 'top',
              backgroundSize: 'cover',
              backgroundImage:
                'url("https://frontend-assets-voxri.s3.sa-east-1.amazonaws.com/report/vox-image-og-banner-card.png")',
            }}
          />
          <div className='flex flex-col w-full md:w-6/12 lg:w-4/12 gap-6 px-8 py-8 md:px-0 md:py-0'>
            <div className='pb-2 flex flex-col gap-4 md:pr-10'>
              <VoxText
                preset={TextSizes.heading5}
                weight={EFontWeight.medium}
                color={Colors.gray500}
              >
                {message}
              </VoxText>
              <div>
                <VoxText
                  preset={TextSizes.heading4}
                  weight={EFontWeight.bold}
                  color={Colors.pink900}
                >
                  {profitability?.total}
                </VoxText>
                <div className='flex gap-2 items-center'>
                  <VoxText
                    preset={TextSizes.heading5}
                    weight={EFontWeight.bold}
                    color={Colors.light}
                  >
                    {profitability?.percent}
                  </VoxText>
                  <span className='h-1 w-1 bg-gray-500 rounded' />
                  <VoxText
                    preset={TextSizes.heading5}
                    weight={EFontWeight.bold}
                    color={Colors.light}
                  >
                    {profitability?.gained}
                  </VoxText>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerFinancialWrapper>
  );
};

export default BannerFinancial;
