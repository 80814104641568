// Component generated with util/vox-create-component.js
import React from 'react';
import { AppHeaderWrapper } from './AppHeader.styled';
import { AppHeaderProps } from './AppHeader.types';
import { AuthContext } from './../../context/AuthContext';
import { SensitiveContext } from './../../context/SensitiveContext';
import { AppProfile, AppContact, AppAccounts } from './../../pages';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Icons,
  VoxHeader,
  VoxHeaderNavigation,
} from '@vox-capital/vox-ui-package';
import { dataDogTags } from '../../analytics';

const MAILTO_SENDER = process.env.REACT_APP_MAILTO_SENDER;
const MAILTO_SUBJECT = 'Contato vox';

export const AppHeader: React.FC<AppHeaderProps> = ({ ...args }) => {
  const MESSAGES = {
    AppDocuments: 'Documentos disponíveis',
    AppExtracts: 'Extratos disponíveis',
    AppVideos: 'POC Vídeos',
    AppHome: 'Olá, @user!',
    AppLegacy: 'O Legado da sua carteira',
    AppImpacts: 'O impacto dos seus investimentos',
  };
  // Ref
  const contactRef = React.useRef<HTMLAnchorElement>(null);
  const headerRef = React.useRef<HTMLDivElement>(null);
  // History
  const history = useHistory();
  const { pathname } = useLocation();
  // Context
  const {
    user,
    userAccessLevel,
    removeSession,
    multiProfiles,
    currentPosition,
  } = React.useContext(AuthContext);
  const { state, dispatch } = React.useContext(SensitiveContext);
  // States
  const [profile, setProfile] = React.useState<boolean>();
  const [contact, setContact] = React.useState<boolean>();
  const [currentMenuItem, setCurrentMenuItem] = React.useState<string>();
  // const [currentPage, setCurrentPage] = React.useState<string>(
  //   window.location.pathname
  // );
  const [message, setMessage] = React.useState<string>('');
  // handlers
  const toggleProfile = () => setProfile(!profile);
  const toggleContact = () => setContact(!contact);
  // Effects
  React.useEffect(() => {
    if (userAccessLevel && userAccessLevel.length > 0 && !currentMenuItem) {
      const menuItem =
        userAccessLevel.find(e => e.link === window.location.pathname)?.name ||
        '';
      setCurrentMenuItem(menuItem);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);
  React.useEffect(() => {
    if (pathname && MESSAGES && user) {
      const name = (MESSAGES as any)[pathname.substring(1, 9999)];
      setMessage(
        (name || '').replace(
          '@user',
          user?.socialName || user?.userName || user?.email || ''
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, MESSAGES]);
  // Component
  return (
    <AppHeaderWrapper {...args} ref={headerRef} id='vox-header'>
      <VoxHeaderNavigation
        backOption={{
          label: 'Encerrar sessão',
          onClick: removeSession,
          icon: Icons.logout,
          actionName: dataDogTags.actions.navigation.logout,
        }}
        otherOtions={[
          {
            label: '',
            icon: Icons.mail,
            onClick: () => {
              if (contactRef && contactRef.current) contactRef.current.click();
            },
            responsive: true,
            actionName: dataDogTags.actions.navigation.contact,
          },
          {
            label: '',
            icon: state?.showSensitiveValues
              ? Icons.visibility
              : Icons.visibility_off,
            onClick: () => {
              dispatch({
                type: 'TOGGLE_SENSITIVE_VALUES',
              });
            },
            actionName: dataDogTags.actions.navigation.sensitive,
          },
          {
            label: 'FluxoVOX',
            icon: Icons.north_east,
            onClick: () =>
              window.open('https://fluxo.voxcapital.com.br/', '_blank'),
            actionName: dataDogTags.actions.navigation.fluxo,
          },
          {
            label: multiProfiles ? 'Trocar de usuário' : 'Meu perfil',
            onClick: toggleProfile,
            icon: multiProfiles ? Icons.supervisor_account : Icons.person,
            responsive: true,
            actionName: dataDogTags.actions.navigation.profile,
          },
        ]}
      />
      <VoxHeader
        status={'NORMAL'}
        message={message}
        // sensitiveContent={!state?.showSensitiveValues}
        showMiniProfitability={pathname !== '/AppHome'}
        profitability={{
          total: state?.showSensitiveValues
            ? currentPosition.grossValue || ''
            : '******,**',
          gained: state?.showSensitiveValues
            ? currentPosition.totalProfitability || ''
            : '**,**%',
          percent: state?.showSensitiveValues
            ? currentPosition.profitability || ''
            : '****,**',
          status: currentPosition.positive ? 'POSITIVE' : 'NEGATIVE',
        }}
        menu={userAccessLevel.map(ua => ({
          name: ua.name,
          label: ua.label,
          icon: ua.leftIcon,
          onClick: () => {
            setCurrentMenuItem(ua.name);
            history.push(`${ua.link}`);
          },
        }))}
        currentMenuItem={currentMenuItem}
        onLogoClick={() => history.push('/AppHome')}
        links={[]}
        shortcuts={[]}
      />
      <a
        rel='noreferrer'
        ref={contactRef}
        href={`mailto:${MAILTO_SENDER}?subject=${MAILTO_SUBJECT}`}
        target='_blank'
        style={{ display: 'none' }}
      >
        Contato
      </a>

      {profile && !multiProfiles && user && (
        <AppProfile toggleProfile={toggleProfile} />
      )}
      {profile && multiProfiles && user && (
        <AppAccounts toggleProfile={toggleProfile} />
      )}
      {contact && user && <AppContact toggleContact={toggleContact} />}
    </AppHeaderWrapper>
  );
};

export default AppHeader;
