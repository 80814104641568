// Component generated with util/vox-create-component.js
import React from 'react';
import { AppEmptyBlockProps } from './AppEmptyBlock.types';
import {
  Colors,
  EButtonPreset,
  EFontWeight,
  TextSizes,
  VoxText,
  VoxButton,
} from '@vox-capital/vox-ui-package';
import { useTranslation } from 'react-i18next';

export const AppEmptyBlock: React.FC<AppEmptyBlockProps> = ({
  refresh,
  title,
  message,
}) => {
  const [c] = useTranslation('common');
  return (
    <div className='w-full flex items-center justify-center md:h-312'>
      <div
        data-testid='vox-graph-empty'
        className='flex flex-col gap-2 items-center text-center w-full md:w-488 rounded-lg px-6 py-16 md:pt-8 md:pb-6'
      >
        <VoxText
          preset={TextSizes.bodyMd}
          weight={EFontWeight.bold}
          color={Colors.pink800}
        >
          {title || c('errors.empty.title')}
        </VoxText>
        <VoxText
          color={Colors.darkBlue}
          preset={TextSizes.bodyMd}
          weight={EFontWeight.medium}
        >
          {message || c('errors.empty.message')}
        </VoxText>
        {refresh ? (
          <div className='mt-4'>
            <VoxButton
              label={c('empty.funds.action.label')}
              onClick={refresh}
              color={Colors.dark200}
              preset={EButtonPreset.solid}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AppEmptyBlock;
