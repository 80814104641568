// Component generated with util/vox-create-component.js
import React from 'react';
import * as R from 'ramda';
import { AppImpactsWrapper } from './AppImpacts.styled';
import { AppImpactsProps } from './AppImpacts.types';
import {
  Colors,
  EAlertType,
  EFontWeight,
  TextSizes,
  VoxLayoutContainer,
  VoxText,
} from '@vox-capital/vox-ui-package';
import { AppEmptyBlock, AppLoadingBlock } from '../../component';
import { useTranslation } from 'react-i18next';
import { dataDogTags, trackView } from '../../analytics';
import AlertContext from '../../context/AlertContext';
import AuthContext from '../../context/AuthContext';
import { Kaleidoscope, useGetPortafolioLazyQuery } from '../../graphql/types.d';
import { formatImpactData } from '../../helpers';
import ImpactsList from './Components/ImpactsList';

export const AppImpacts: React.FC<AppImpactsProps> = props => {
  // Analytics
  trackView(dataDogTags.views.privateHome, dataDogTags.views.privateLegacy);
  // Hooks
  const [l] = useTranslation('legacy');
  const [c] = useTranslation('common');
  const [e] = useTranslation('errors');
  // Context
  const { showAlert, closeAlert } = React.useContext(AlertContext);
  const { user, clearSession } = React.useContext(AuthContext);
  // Navigation
  // const history = useHistory();
  // States
  const [localLoad, setLocalLoad] = React.useState(true);
  const [histories, setHistories] = React.useState<Kaleidoscope[]>([]);
  // Graphql hooks
  const [getPortfolio, { data, error, loading, refetch }] =
    useGetPortafolioLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  // Get Portfolio
  React.useEffect(() => {
    if (user && user.personId) {
      getPortfolio({
        variables: {
          personId: `${user.personId}`,
        },
      });
    }
  }, [getPortfolio, user]);
  // Fill legacy historias
  React.useEffect(() => {
    if (error) {
      const gqlError = error.graphQLErrors[0];
      const errorCode = gqlError ? gqlError.extensions.code : true;
      if (errorCode && errorCode.toString() === 'UNAUTHENTICATED') {
        showAlert({
          title: e('errors.auth.SessionExpired.title'),
          message: e('errors.auth.SessionExpired.message'),
          type: EAlertType.error,
          primaryAlertAction: () => {
            clearSession();
            closeAlert();
          },
        });
      } else {
        showAlert({
          title: c('errors.common.wrong.title'),
          message: c('errors.common.wrong.message'),
          type: EAlertType.error,
        });
      }
    } else if (data && data.portfolio) {
      const dataPosition: any[] = data.portfolio.positions || [];

      const kaleidoscopeList: Kaleidoscope[] =
        formatImpactData(dataPosition) || [];

      setHistories(kaleidoscopeList);
    }
    setTimeout(() => setLocalLoad(false), 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  if (loading || localLoad) return <AppLoadingBlock />;

  if (!data || !data.portfolio || error || !histories.length)
    return (
      <AppEmptyBlock
        refresh={() =>
          refetch
            ? refetch({
                personId: `${user?.personId}`,
              })
            : () => {}
        }
      />
    );

  return (
    <AppImpactsWrapper {...props} className='flex flex-col w-full items-center'>
      <div className='bg-gray-100 flex w-full items-center justify-center pt-14 pb-24'>
        <VoxLayoutContainer className='flex flex-col'>
          <div className='flex flex-col gap-8'>
            <div
              id='legacy-presentation-info'
              className='flex flex-col gap-2 mb-10 w-full md:w-4/12 lg:w-full md:pt-4 lg:pt-8'
            >
              <span className='text-2xl lg:text-4xl font-bold text-darkBlue-300'>
                {l('legacy.planet.title')}
              </span>
              <VoxText
                preset={TextSizes.bodyLg}
                weight={EFontWeight.medium}
                color={Colors.gray500}
              >
                {l('legacy.planet.description')}
              </VoxText>
            </div>
          </div>
          <ImpactsList histories={histories} />
        </VoxLayoutContainer>
      </div>
    </AppImpactsWrapper>
  );
};

export default AppImpacts;
