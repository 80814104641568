import { VoxLayoutContainer } from '@vox-capital/vox-ui-package';
import React from 'react';
import { BannerFinancial } from '../../../ui';
import { LegacyFinancialProps } from '../Types/LegacyData';

const LegacyFinancial: React.FC<LegacyFinancialProps> = ({ financial }) => {
  return (
    <div className='bg-darkBlue-100 flex justify-center w-full'>
      <VoxLayoutContainer padding={false}>
        <BannerFinancial {...financial} />
      </VoxLayoutContainer>
    </div>
  );
};

export default LegacyFinancial;
