import React from 'react';
import { Kaleidoscope } from '../../../graphql/types';
import { Legacy } from '../../../ui';

const ImpactsList: React.FC<{
  histories: Kaleidoscope[];
}> = ({ histories }) => {
  return (
    <div
      id='list'
      className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 xl:grid-cols-5 gap-6 cursor-pointer w-full'
    >
      {histories.map(e => (
        <Legacy key={e.id} kaleidoscope={e} />
      ))}
    </div>
  );
};

export default ImpactsList;
