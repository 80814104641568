import React from 'react';
import {
  Colors,
  EButtonPreset,
  EFontWeight,
  Icons,
  TextSizes,
  VoxButton,
  VoxCardStatement,
  VoxLayoutContainer,
  VoxText,
} from '@vox-capital/vox-ui-package';
import { motion } from 'framer-motion';
import uniqid from 'uniqid';
import { LegacyCompanyProps, LegacyTesesProps } from '../Types/LegacyData';
import { useTranslation } from 'react-i18next';
import { AppLegacyAssetWrapper } from '../AppLegacy.styled';
import { Asset } from '../../../ui';
import { dataDogTags } from '../../../analytics';

const AppLegacyTeses: React.FC<
  LegacyTesesProps & React.HTMLAttributes<HTMLDivElement>
> = ({ thesis, companies, ...args }) => {
  const [assets, setAssets] = React.useState<LegacyCompanyProps[]>(companies);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [currentTese, setCurrentTese] = React.useState(thesis[0]);

  const [l] = useTranslation('legacy');

  const assetRef = React.useRef<HTMLDivElement>(null);
  const carrouselRef = React.useRef<HTMLDivElement>(null);

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < assets.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  React.useEffect(() => {
    if (currentTese) {
      setAssets(
        companies.filter(companie =>
          companie.thesis.find(thesi => thesi === currentTese.id)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTese]);

  return (
    <div {...args} className='w-full'>
      <div
        id='teses-block'
        className='bg-light-100 pt-16 pb-4 flex w-full items-center justify-center'
      >
        <VoxLayoutContainer>
          <div id='title' className='w-8/12 md:w-6/12'>
            <span className='text-2xl lg:text-4xl font-bold text-darkBlue-300'>
              {l('legacy.teses.title')}
            </span>
          </div>
          <hr className='h-8 border-0' />
          <div className='flex flex-wrap gap-4 md:gap-8 w-full'>
            {thesis.map(e => (
              <div
                key={e.id}
                className='cursor-pointer'
                onClick={() => setCurrentTese(e)}
                data-vox-action={dataDogTags.actions.legacy.tesis}
              >
                <VoxText
                  preset={TextSizes.bodyMd}
                  weight={EFontWeight.bold}
                  color={
                    e.id === currentTese.id ? Colors.pink900 : Colors.gray500
                  }
                >
                  {e.name}
                </VoxText>
              </div>
            ))}
          </div>
        </VoxLayoutContainer>
      </div>
      <div
        id='teses-detail-block'
        className='pt-16 md:pt-24 flex flex-col w-full items-center justify-center overflow-x-hidden'
        style={{
          background:
            'linear-gradient(172deg, #14131F 23.73%, #0A090F 50.82%, #07060A 89.06%)',
        }}
      >
        <VoxLayoutContainer
          style={{
            backgroundImage: `url(${currentTese.image})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '60%',
            backgroundPosition: 'right top',
          }}
        >
          <div id='teses-info-content' className='content'>
            <div className='w-full md:w-8/12 xl:w-6/12 flex flex-col gap-4 mb-12 md:mb-16'>
              <span className='text-xl lg:text-2xl font-bold text-pink-900'>
                As suas escolhas de investimento em {currentTese.name}
              </span>
              <VoxText
                preset={TextSizes.bodyMd}
                weight={EFontWeight.medium}
                color={Colors.gray500}
              >
                {currentTese.description}
              </VoxText>
            </div>
            <div id='companies'>
              <div className='title flex flex-col gap-4 w-full'>
                <VoxText
                  preset={TextSizes.bodyMd}
                  weight={EFontWeight.bold}
                  color={Colors.light}
                >
                  {l('legacy.teses.companies')}
                </VoxText>
                <div
                  id='companies-data'
                  className='flex flex-col md:flex-row justify-between md:items-center w-full'
                >
                  <div
                    id='companies-list'
                    className='list flex flex-wrap gap-4 md:gap-2'
                  >
                    {assets.map((e, i) => (
                      <div
                        id={e.id}
                        key={uniqid()}
                        onClick={() => setCurrentPage(i)}
                        className={`item cursor-pointer ${
                          currentPage === i ? 'text-pink-800' : 'text-gray-500'
                        } hover:text-gray-200 font- font-bold text-sm`}
                      >
                        <span>{e.name}</span>
                      </div>
                    ))}
                  </div>
                  {assets.length > 2 ? (
                    <div
                      id='companies-pagination'
                      className='hidden md:flex gap-2'
                    >
                      <VoxButton
                        color={Colors.light}
                        onClick={prevPage}
                        preset={EButtonPreset.link}
                        label={l('legacy.teses.navigation.prev')}
                        leftAddon={Icons.chevron_left}
                        compact
                      />
                      <VoxButton
                        color={Colors.light}
                        onClick={nextPage}
                        preset={EButtonPreset.link}
                        label={l('legacy.teses.navigation.next')}
                        rightAddon={Icons.chevron_right}
                        compact
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          {assets.length ? (
            <div className='flex md:hidden mt-8 mb-8'>
              <VoxCardStatement
                name={uniqid()}
                description={assets[currentPage].description}
                asset={assets[currentPage].name}
                image={assets[currentPage].image}
              />
            </div>
          ) : (
            <></>
          )}
          {assets.length ? (
            <motion.div
              ref={carrouselRef}
              id='teses-companies-content'
              className='companies mb-8 mt-4 min-w-min hidden md:flex gap-8 w-screen items-center justify-start overflow-auto'
              animate={{
                x:
                  currentPage *
                  ((assetRef.current?.clientWidth ?? 0) + 32) *
                  -1,
              }}
              transition={{
                duration: 0.4,
                easings: 'easeInOut',
              }}
            >
              {assets.map((e, i) => (
                <motion.div key={uniqid()} className='bg-transparent'>
                  <motion.div
                    ref={i === 0 ? assetRef : null}
                    key={uniqid()}
                    className='w-312 sm:w-488 md:w-512 lg:w-640 opacity-100'
                  >
                    <AppLegacyAssetWrapper>
                      <Asset asset={e} />
                    </AppLegacyAssetWrapper>
                  </motion.div>
                </motion.div>
              ))}
            </motion.div>
          ) : (
            <></>
          )}
        </VoxLayoutContainer>
      </div>
    </div>
  );
};

export default AppLegacyTeses;
