// Component generated with util/vox-create-component.js
import styled from 'styled-components';

/**
 * Components
 */
export const AppLegacyWrapper = styled.div``;

export const AppLegacyAssetWrapper = styled.div`
  .asset-name {
    color: ${({ theme }) => theme.colors.pink900};
  }
`;
