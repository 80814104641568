import React from 'react';
import uniqid from 'uniqid';
import {
  Colors,
  EButtonPreset,
  EFontWeight,
  Icons,
  TextSizes,
  VoxButton,
  VoxDocumentItem,
  VoxLayoutContainer,
  VoxText,
} from '@vox-capital/vox-ui-package';
import { dataDogTags } from '../../../analytics';
import { useTranslation } from 'react-i18next';

const HomeDocuments: React.FC<{
  documents: Document[];
  downloadDocument: (id: string) => void;
  showMore: () => void;
}> = ({ documents, downloadDocument, showMore }) => {
  const [c] = useTranslation('common');
  return (
    <div className='bg-gray-100 w-full pt-16 pb-10 flex items-center justify-center'>
      <VoxLayoutContainer>
        {documents && documents.length && (
          <div
            className='portfolio flex flex-col md:flex-row md:gap-8'
            data-testid='vox-documents'
          >
            <div className='description flex flex-col gap-4 w-full md:w-4/12'>
              <VoxText preset={TextSizes.heading4} weight={EFontWeight.bold}>
                Atualizações VOX
              </VoxText>
              <VoxText
                preset={TextSizes.bodyLg}
                weight={EFontWeight.bold}
                color={Colors.gray500}
              >
                Aqui você encontra relatórios que explicam o que está
                acontecendo no seu portfólio.
              </VoxText>
            </div>
            <div className='list py-8 w-full md:w-8/12 flex flex-col items-end gap-4'>
              <div className='documents flex flex-col gap-2 w-full'>
                {documents.map((doc: any) => (
                  <VoxDocumentItem
                    key={uniqid()}
                    label={doc.fund}
                    description={doc.type}
                    icon={Icons.system_update_alt}
                    onClick={() => {
                      downloadDocument(doc.id);
                    }}
                    data-vox-action={
                      dataDogTags.actions.home.portfolio.showMore
                    }
                  />
                ))}
              </div>
              <div className='footer'>
                <VoxButton
                  color={Colors.dark200}
                  preset={EButtonPreset.link}
                  label={c('actions.showmore')}
                  onClick={showMore}
                  data-vox-action={dataDogTags.actions.home.portfolio.showMore}
                  rightAddon={Icons.chevron_right}
                />
              </div>
            </div>
          </div>
        )}
      </VoxLayoutContainer>
    </div>
  );
};

export default HomeDocuments;
