/* eslint-disable react-hooks/exhaustive-deps */
// Component generated with util/vox-create-component.js
import React from 'react';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';
import { AuthAccountPagination } from './AuthChooseAccount.styled';
import { AuthChooseAccountProps } from './AuthChooseAccount.types';
import { AuthCommons, Loading } from './../../ui';
import { UI } from './../../component';
import { useTranslation } from 'react-i18next';
import { AuthContext } from './../../context/AuthContext';

import { GetStatementsDocument } from '../../graphql/types.d';
import { client } from './../../apollo/config';
import AlertContext from '../../context/AlertContext';
import { getMonthName, printStatement } from '../../helpers';
import moment from 'moment';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import {
  Colors,
  EButtonPreset,
  EFontWeight,
  TextSizes,
} from '@vox-capital/vox-ui-package';
import { EAlertType } from '@vox-capital/vox-ui-package';
import { dataDogTags, trackView } from '../../analytics';

export const AuthChooseAccount: React.FC<AuthChooseAccountProps> = ({
  ...args
}) => {
  trackView(
    dataDogTags.views.authChooseAccount,
    dataDogTags.views.authChooseAccount
  );
  const profilesPerPage = 5;
  // Refs
  const searchRef = React.useRef<HTMLInputElement>(null);
  const listRef = React.useRef<HTMLDivElement>(null);
  // States
  const [isLoading] = React.useState<boolean>(false);
  const [load, setLoad] = React.useState(false);
  const [account, setAccount] = React.useState<any>();
  const [profiles, setProfiles] = React.useState<any>([]);
  // const [profilesPerPage, setProfilesPerPage] = React.useState<number>(3);
  const [page, setPage] = React.useState<number>(1);
  // Context
  const { allProfiles, clearSession, removeSession, setSession, accessToken } =
    React.useContext(AuthContext);
  // Effects
  React.useEffect(() => {
    if (searchRef.current) {
      searchRef.current.addEventListener('keyup', onSearchChange);
    }
    return () => {
      searchRef.current?.removeEventListener('keyup', onSearchChange);
    };
  }, [searchRef]);
  React.useEffect(() => {
    if (allProfiles && page !== 9999) {
      const cloneProfiles = [...allProfiles];
      setProfiles(cloneProfiles.splice(0, profilesPerPage * page));
    }
  }, [allProfiles, profilesPerPage, page]);

  const { showAlert, closeAlert } = React.useContext(AlertContext);

  const data = new Date().setMonth(new Date().getMonth() - 1);
  const month = getMonthName(new Date(data).toString()).toLowerCase();
  // languages
  const [t] = useTranslation('auth');
  const [s] = useTranslation('statements');
  // History
  const history = useHistory();
  if (R.isEmpty(allProfiles)) {
    clearSession();
    history.push('/AuthSignIn');
    return null;
  }
  // Handlers
  const onSearchChange = (val: any) => {
    if (val && val.target) {
      const cloneProfiles = [...allProfiles];
      const value = `${val.target.value}`;
      const filterProfiles = cloneProfiles
        .filter(
          ({ name = '' }) => R.match(new RegExp(`${value}`, 'i'), name).length
        )
        .splice(0, profilesPerPage);
      setProfiles([...filterProfiles]);
      if (!value) setPage(1);
      else setPage(9999);
    }
  };
  const signIn = () => {
    setSession({
      user: account,
      profiles: allProfiles,
      aToken: `${accessToken}`,
    });
  };
  // const getMoreUsers = () => {
  //   if (allProfiles.length > profilesPerPage * page) setPage(page + 1);
  // };

  const downloadStatements = async () => {
    closeAlert();
    setLoad(true);
    try {
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      const firstDayString = firstDay.toISOString().split('T')[0];
      const lastDayString = `${
        lastDay.toISOString().split('T')[0].split('-')[0]
      }-${lastDay.toISOString().split('T')[0].split('-')[1]}`;

      const results = [];

      const BATCH_SIZE = 10;
      let position = 0;

      while (position < allProfiles.length) {
        const batch = allProfiles.slice(position, position + BATCH_SIZE);
        const batchResults = await Promise.all(
          batch.map((profile: any) =>
            client
              .query({
                query: GetStatementsDocument,
                variables: {
                  personId: `${profile?.personId}`,
                  date: lastDayString,
                  fundIdList: [],
                },
              })
              .then(res => ({ ...res, profile }))
          )
        );
        results.push(...batchResults);
        position += BATCH_SIZE;
      }

      const statements = results.map((statement: any) => ({
        statement: statement.data.statement,
        user: statement.profile,
      }));

      const zip = new JSZip();

      // eslint-disable-next-line array-callback-return
      statements.map((statementResponse: any) => {
        const { statement, user } = statementResponse;

        const zipFolder = zip.folder(user.name);
        statement.map((fund: any) => {
          const operations = fund.operations
            ? fund.operations.map((opt: any) => ({
                ...opt,
                personId: '',
                description: s(`statements.files.${opt?.description}`),
              }))
            : [];
          const doc = printStatement({ ...fund, operations }, user);
          const currentDate = moment(firstDayString).format('DD-MM-YYYY');
          const fileName = `${user.name} - ${fund.name} - ${currentDate}.pdf`;
          zipFolder?.file(fileName, doc.output('blob'));
          return { doc, fileName };
        });
      });

      zip.generateAsync({ type: 'blob' }).then(content => {
        saveAs(content, 'statements.zip');
      });
    } catch (error) {
      showAlert({
        message: t('auth.chooseAccount.alert.error', {
          month,
        }),
        type: EAlertType.error,
        title: t('auth.chooseAccount.alert.errorTitle'),
      });
    }
    setLoad(false);
  };

  return (
    <AuthCommons.AuthCommonContainer {...args}>
      {load && <Loading loadingMessage='Aguarde' />}
      <AuthCommons.AuthCommonBody>
        <AuthCommons.AuthCommonFormContainer>
          <AuthCommons.AuthCommonFormHeader>
            <UI.VoxText
              preset={TextSizes.heading5}
              color={Colors.gray500}
              weight={EFontWeight.medium}
              data-subtitle
            >
              {t('auth.chooseAccount.label')}
            </UI.VoxText>
            <UI.VoxText
              preset={TextSizes.heading3}
              color={Colors.pink800}
              weight={EFontWeight.bold}
            >
              {t('auth.chooseAccount.title')}
            </UI.VoxText>
            <div className='h-2' />
            <UI.VoxText
              isLink
              weight={EFontWeight.bold}
              color={Colors.light}
              onClick={() =>
                showAlert({
                  title: t('auth.chooseAccount.alert.title'),
                  message: t('auth.chooseAccount.alert.description', {
                    month,
                  }),
                  type: EAlertType.success,
                  primaryAlertAction() {
                    downloadStatements();
                  },
                })
              }
            >
              {t('auth.chooseAccount.download_statements', {
                month,
              })}
            </UI.VoxText>
          </AuthCommons.AuthCommonFormHeader>
          <AuthCommons.AuthCommonFormBody>
            {allProfiles.length > 2 && (
              <UI.VoxInput
                ref={searchRef}
                dark
                label=''
                name='search'
                placeholder='Digite o nome do cotista para fazer a busca'
                hasIcon
                // icon={Icons.search}
              />
            )}
            <div
              ref={listRef}
              className='flex max-h-128 md:max-h-312 flex-col bg-darkBlue-100 rounded-md box-border border-2 border-gray-500 overflow-y-auto'
            >
              {!profiles.length ? (
                <UI.VoxText color={Colors.gray500}>
                  Não achamos ninguem com esse nome
                </UI.VoxText>
              ) : (
                allProfiles.map((acc: any) => (
                  <div
                    className={`w-full cursor-pointer ${
                      acc.id === account?.id ? 'bg-darkBlue-200' : ''
                    } `}
                    key={acc.id}
                    data-testid={`vox-user-${acc.id}`}
                    onClick={() =>
                      setAccount(account && acc.id === account.id ? null : acc)
                    }
                    // isSelected={acc.id === account?.id}
                  >
                    <div className='flex px-4 box-border items-center py-3 md:py-0 border-b-2 border-gray-500 md:h-14'>
                      <UI.VoxText
                        color={Colors.light}
                        weight={EFontWeight.medium}
                      >
                        {acc.name}
                      </UI.VoxText>
                    </div>
                    {/* <UI.VoxAvatar dark size='small' title={`${acc.name}`} /> */}
                  </div>
                ))
              )}
            </div>
            <AuthAccountPagination>
              <UI.VoxText
                color={Colors.light}
                preset={TextSizes.body}
                weight={EFontWeight.medium}
              >
                {allProfiles.length} cotista(s) para administrar
              </UI.VoxText>
              {/* {allProfiles.length > profilesPerPage * page && (
                <UI.VoxText
                  color={Colors.light}
                  weight={EFontWeight.bold}
                  isLink
                  onClick={() => !isLoading && getMoreUsers()}
                >
                  mostrar mais cotista(s) +
                </UI.VoxText>
              )} */}
            </AuthAccountPagination>
          </AuthCommons.AuthCommonFormBody>
          <AuthCommons.AuthCommonFormFooter>
            <UI.VoxButton
              label='Cancelar'
              color={Colors.light}
              preset={EButtonPreset.link}
              data-testid='vox-back-btn'
              onClick={() => {
                if (!isLoading) {
                  removeSession();
                  history.goBack();
                }
              }}
            />
            <UI.VoxButton
              label={t('auth.chooseAccount.forms.button_send.label', {})}
              preset={EButtonPreset.solid}
              color={Colors.light}
              onClick={() => signIn()}
              isLoading={isLoading}
              data-testid='vox-choose-btn'
              disabled={!account}
            />
          </AuthCommons.AuthCommonFormFooter>
        </AuthCommons.AuthCommonFormContainer>
      </AuthCommons.AuthCommonBody>
    </AuthCommons.AuthCommonContainer>
  );
};

export default AuthChooseAccount;
