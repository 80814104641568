import React, { createContext, useState, useEffect } from 'react';
import { MenuItemProps } from '@vox-capital/vox-ui-package/ui/MenuItem/MenuItem.types';
import {
  MyProfileDocument,
  GetProfileDocument,
  Individual,
  AcceptTermDocument,
} from './../graphql/types.d';
import { useTranslation } from 'react-i18next';
import { client } from './../apollo/config';
import { User } from '../@types/user';
import { saveItemToLocalstorage, getItemFromLocalstorage } from './../helpers';
import { Icons } from '@vox-capital/vox-ui-package';
import { clearUSerSession, setUserSession } from './../analytics';

type TGetProfile = {
  userName: string;
  id: string;
};

type TProfile = {
  user: User;
  profiles: User[];
};

type TSession = TProfile & {
  aToken: string;
  rToken?: string;
};

type TPosition = {
  grossValue: string | null | undefined;
  profitability: string | null | undefined;
  totalProfitability: string | null | undefined;
  positive?: boolean | null | undefined;
};

export interface IAuthContextData {
  appLoading: boolean;
  appError: any;
  signed: boolean;
  accessToken: string | null;
  user: User | null;
  userSecret: any | null;
  userAccessLevel: MenuItemProps[];
  resetUserName: any | null;
  multiProfiles: boolean;
  allProfiles: User[] | [];
  currentPosition: TPosition;
  setCurrentPosition: (position: TPosition) => void;
  removeSession: () => void;
  updateSession: (user: any) => void;
  saveUserName: (user: any) => void;
  saveUserScret: (secret: any) => void;
  setSession: (s: TSession) => void;
  setTempSession: (s: TSession) => void;
  clearSession: () => void;
  getProfile: (p: TGetProfile) => User | {};
  getDependents: (personId: string) => Promise<User[]>;
  acceptTerm: (id: any) => {};
}

export const AuthContext = createContext<IAuthContextData>(
  {} as IAuthContextData
);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // languages
  const [n] = useTranslation('navigation');
  // States
  const [user, setUser] = useState<User | null>(null);
  const [userSecret, setUserScret] = useState<any | null>(null);
  const [signed, setSigned] = useState<boolean>(false);
  const [profile, setProfile] = useState<boolean>(false);
  const [multiProfiles, setMultiProfile] = useState<boolean>(false);
  const [allProfiles, setAllProfiles] = useState<User[] | []>([]);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [resetUserName, setResetUserName] = useState<any | null>(null);
  const [appLoading, setAppLoading] = useState<boolean>(true);
  const [appError, setAppError] = useState<any>(null);
  const [userAccessLevel, setUserAccessLevel] = useState<MenuItemProps[]>([]);
  const [currentPosition, setPosition] = useState<TPosition>({
    grossValue: '',
    profitability: '',
    totalProfitability: '',
  });
  // Check Token
  const checkUserToken = async () => {
    const storageToken = await getItemFromLocalstorage('@Auth:token', 'STRING');
    if (storageToken) {
      setSigned(false);
    }
  };
  // Effects
  useEffect(() => {
    window.addEventListener('storage', checkUserToken);
    // return () => {
    // 	window.removeEventListener('storage', checkUserToken);
    // };
  }, []);
  useEffect(() => {
    if (profile) return;
    (async () => {
      const storageUser = await getItemFromLocalstorage('@Auth:user', 'JSON');
      const storageToken = await getItemFromLocalstorage(
        '@Auth:token',
        'STRING'
      );
      const storageAccessLevel = await getItemFromLocalstorage(
        '@Auth:access',
        'JSON'
      );
      const position =
        (await getItemFromLocalstorage('@Auth:position', 'JSON')) || {};
      if (storageUser && storageToken && storageAccessLevel) {
        const profiles: any =
          getItemFromLocalstorage('@Auth:multiProfiles', 'JSON') || [];
        setAllProfiles(profiles);
        setUserAccessLevel(storageAccessLevel);
        setAccessToken(storageToken);
        setUser(storageUser);
        setSigned(true);
        setMultiProfile(profiles.length);
        setProfile(true);
        setPosition(position);
      }
      setAppLoading(false);
    })();
  }, [user, profile]);
  // helpers
  const getDependents: (
    personId: string
  ) => Promise<User[]> = async personId => {
    if (!personId) return [];

    const { data, error } = await client.query({
      query: GetProfileDocument,
      variables: {
        personId,
      },
    });
    if (error || !data.profiles.length) {
      return [];
    }
    const formatProfiles: User[] = data.profiles
      // .sort((a: any, b: any) =>
      // 	a.nome_completo > b.nome_completo ? 1 : b.nome_completo > a.nome_completo ? -1 : 0,
      // )
      .map((p: Individual, i: number) => ({
        userName: `${p?.username}`,
        id: `${p?.personId}`,
        personId: `${p?.personId}`,
        socialName: `${p?.socialName || `Usuario ${i + 1}`}`,
        name: `${p?.fullName || p?.socialName || `Usuario ${i + 1}`}`,
        email: `${p?.email}`,
      }));
    return formatProfiles;
  };
  const getProfile: (p: TGetProfile) => User | {} = async ({
    userName,
    id,
  }: TGetProfile) => {
    const response = await client.query({
      query: MyProfileDocument,
      variables: {
        loginId: id,
      },
    });
    const { data, errors } = response;
    if (errors) {
      return {
        errors,
      };
    } else if (data && data.myInfo) {
      const personId = data.myInfo.personId;
      return {
        userName,
        id,
        personId,
        version: data.myInfo?.version,
        socialName: data.myInfo?.individual?.socialName,
        name: data.myInfo?.individual?.fullName,
        email: data.myInfo?.individual?.email,
      };
    }
    return {};
  };
  const acceptTerm = async (loginId: any) => {
    const response = await client.mutate({
      mutation: AcceptTermDocument,
      variables: {
        loginId,
      },
    });
    const { data, errors } = response;
    if (errors) return false;
    else if (data && data.acceptTerm === 'Ok') return true;
    return false;
  };
  // removeSession
  const removeSession = async () => {
    localStorage.clear();
    setUser(null);
    setAccessToken(null);
    setSigned(false);
    setProfile(false);
  };
  // setSession
  const setTempSession = (props: TSession) => {
    const { user: pUser, profiles: pProfiles, aToken } = props;
    setResetUserName(pUser);
    setAccessToken(aToken);
    setAllProfiles(pProfiles);
  };
  const setSession = (props: TSession) => {
    const { user: pUser, profiles: pProfiles, aToken } = props;
    // TODO: Adicionar multilanguage
    const accessLevel: any = [
      {
        label: n('navigation.legacy'),
        name: 'investments',
        // leftIcon: 'invest',
        link: '/AppHome',
        leftIcon: Icons.monetization_on,
      },
      {
        label: n('navigation.statements'),
        name: 'extracts',
        // leftIcon: 'dolar',
        link: '/AppExtracts',
        leftIcon: Icons.file_dolar,
      },
      {
        label: n('navigation.files'),
        name: 'documents',
        // leftIcon: 'document',
        link: '/AppDocuments',
        leftIcon: Icons.folder_zip,
      },
      {
        label: n('navigation.impact'),
        name: 'legacy',
        // leftIcon: 'document',
        link: '/AppLegacy',
        leftIcon: Icons.eco,
      },
      {
        label: n('navigation.history'),
        name: 'history',
        // leftIcon: 'document',
        link: '/AppImpacts',
        leftIcon: Icons.interests,
      },
    ];
    saveItemToLocalstorage(
      '@Auth:impersonateid',
      `${resetUserName?.personId || pUser.personId}`,
      'STRING'
    );
    saveItemToLocalstorage(
      'version',
      `${resetUserName?.version || ''}`,
      'STRING'
    );
    saveItemToLocalstorage('@Auth:access', accessLevel, 'JSON');
    saveItemToLocalstorage('@Auth:user', pUser, 'JSON');
    saveItemToLocalstorage('@Auth:multiProfiles', pProfiles, 'JSON');
    saveItemToLocalstorage('@Auth:multiProfiles', pProfiles, 'JSON');
    saveItemToLocalstorage('@Auth:token', aToken, 'STRING');
    setUser(pUser);
    setAccessToken(aToken);
    setResetUserName(null);
    setUserScret(null);
    // Set data dog user
    setUserSession({ ...pUser, accessLevel, profiles: pProfiles });
  };
  // clearSession
  const clearSession = () => {
    localStorage.clear();
    removeSession();
    setSigned(false);
    setUserScret(null);
    setResetUserName(null);
    setAllProfiles([]);
    setMultiProfile(false);
    setAppError(false);
    setPosition({ grossValue: '', totalProfitability: '', profitability: '' });
    setUserAccessLevel([]);
    clearUSerSession();
  };
  // Save Username
  const saveUserName = (userName: any) => {
    setResetUserName(userName);
  };
  // Save User Secret
  const saveUserScret = (secret: any) => {
    setUserScret(secret);
  };
  // Update User
  const updateSession = (newUserSession: any) => setUser(newUserSession);
  // Update current position
  const setCurrentPosition = (position: TPosition) => {
    saveItemToLocalstorage('@Auth:position', position, 'JSON');
    setPosition(position);
  };
  return (
    <AuthContext.Provider
      value={{
        user,
        userSecret,
        userAccessLevel,
        resetUserName,
        saveUserName,
        saveUserScret,
        signed,
        accessToken,
        removeSession,
        setSession,
        setTempSession,
        getProfile,
        getDependents,
        clearSession,
        appLoading,
        appError,
        multiProfiles,
        allProfiles,
        updateSession,
        setCurrentPosition,
        currentPosition,
        acceptTerm,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
