import * as R from 'ramda';
import React from 'react';
import {
  Colors,
  EButtonPreset,
  EFontWeight,
  Icons,
  TextSizes,
  VoxButton,
  VoxLayoutContainer,
  VoxText,
} from '@vox-capital/vox-ui-package';

import { Legacy, Quote } from '../../../ui';
import { LegacyDataProps } from '../Types/LegacyData';
import { useTranslation } from 'react-i18next';
import uniqid from 'uniqid';
import { useHistory } from 'react-router-dom';
import { dataDogTags } from '../../../analytics';

const LegacyData: React.FC<
  LegacyDataProps & React.HTMLAttributes<HTMLDivElement>
> = ({ quote, legacy, ...args }) => {
  // Navigation
  const history = useHistory();
  // Translations
  const [l] = useTranslation('legacy');

  if (!legacy.length) return <></>;

  return (
    <div
      id='legacy-block'
      className='flex items-center justify-center py-16 md:py-24'
      {...args}
    >
      <VoxLayoutContainer>
        <div className='flex flex-col lg:flex-row gap-16'>
          <div
            id='legacy-presentation'
            className='flex flex-col md:flex-row lg:flex-col gap-2 md:gap-6 w-full lg:w-5/12'
          >
            <div
              id='legacy-presentation-info'
              className='flex flex-col gap-2 mb-10 w-full md:w-4/12 lg:w-full md:pt-4 lg:pt-8'
            >
              <span className='text-2xl lg:text-4xl font-bold text-darkBlue-300'>
                {l('legacy.planet.title')}
              </span>
              <VoxText
                preset={TextSizes.bodyLg}
                weight={EFontWeight.medium}
                color={Colors.gray500}
              >
                {l('legacy.planet.description')}
              </VoxText>
            </div>
            <div
              id='legacy-presentation-quote'
              className='w-full md:w-8/12 lg:w-full'
            >
              <Quote {...quote} />
            </div>
          </div>
          <div
            id='legacy-cards'
            className='w-full lg:w-7/12 flex flex-col gap-4 justify-end items-end'
          >
            <div
              id='list'
              className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 gap-6 cursor-pointer w-full'
            >
              {R.take(4, legacy).map(e => (
                <Legacy key={uniqid()} kaleidoscope={e} />
              ))}
            </div>
            {legacy.length > 4 ? (
              <div>
                <VoxButton
                  color={Colors.dark200}
                  preset={EButtonPreset.link}
                  label={l('legacy.planet.actions.showmore')}
                  onClick={() => history.push('/AppImpacts')}
                  rightAddon={Icons.chevron_right}
                  data-vox-action={dataDogTags.actions.legacy.moreImpact}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </VoxLayoutContainer>
    </div>
  );
};

export default LegacyData;
