// Component generated with util/vox-create-component.js
import * as R from 'ramda';
import React from 'react';
import moment from 'moment';
import { AppHomeGraph } from './AppHome.styled';
import { AppHomeProps } from './AppHome.types';
import { UI, AppLoadingBlock, AppEmptyBlock } from './../../component';
import { graphTags } from './../../constants';
import { useTranslation } from 'react-i18next';
import {
  useGetPortafolioLazyQuery,
  useGetVoxDocumentsLazyQuery,
} from './../../graphql/types.d';
import {
  transFormCurreny,
  formatGraphData,
  transFormNumber,
  formatDocumentsData,
} from './../../helpers';
import { AuthContext } from './../../context/AuthContext';
import { AlertContext } from './../../context/AlertContext';
import { AppContext } from './../../context/AppContext';
import { SensitiveContext } from './../../context/SensitiveContext';
// import { useTheme } from 'styled-components';
import { Loading } from './../../ui';
import { downLoadDocumentFile } from './../../service/api';
import { useHistory } from 'react-router-dom';
import { EAlertType, VoxLayoutContainer } from '@vox-capital/vox-ui-package';
import {
  dataDogTags,
  trackAction,
  trackError,
  trackView,
} from '../../analytics';
import HomePositions from './Components/HomePositions';
import HomeDocuments from './Components/HomeDocuments';
import HomeFinancial from './Components/HomeFinancial';

export const AppHome: React.FC<AppHomeProps> = ({ ...props }) => {
  trackView(dataDogTags.views.privateHome, dataDogTags.views.privateHome);
  // Navigation
  const history = useHistory();
  // Refs
  const refHome = React.useRef<HTMLDivElement>(null);
  const refGraphContainer = React.useRef<HTMLDivElement>(null);
  // Theme
  // const theme = useTheme();
  // graphql hooks
  const [getPortfolio, { data, error, loading, refetch }] =
    useGetPortafolioLazyQuery({
      notifyOnNetworkStatusChange: true,
    });
  const [getVoxDocumentsData, { data: voxData, loading: voxLoading }] =
    useGetVoxDocumentsLazyQuery({
      notifyOnNetworkStatusChange: false,
    });
  // Translations
  const [d] = useTranslation('documents');
  const [c] = useTranslation('common');
  const [e] = useTranslation('errors');
  // Context
  const { dispatch } = React.useContext(AppContext);
  const { user, setCurrentPosition, clearSession, currentPosition } =
    React.useContext(AuthContext);
  const { showAlert, closeAlert } = React.useContext(AlertContext);
  const { state } = React.useContext(SensitiveContext);
  // States
  const [localLoad, setLocalLoad] = React.useState(true);
  const [downloading, setDownloading] = React.useState(false);
  const [positions, setPositions] = React.useState<any[]>([]);
  const [documents, setDocuments] = React.useState<any[]>([]);
  const [profitability, setProfitability] = React.useState<any>(0);
  const [lastPositionDate, setLastPositionDate] = React.useState<any>(
    new Date()
  );
  const [historyData, setHistoryData] = React.useState<any[]>([]);
  // const [historyIpact, setHistoryImpact] = React.useState<any[]>([]);
  // const [ histories, setHistories] = React.useState<any[]>([]);
  const [profitabilityValue, setProfitabilityValue] = React.useState<number>(0);
  // Effects
  // Star calling porfolio data
  React.useEffect(() => {
    if (R.isEmpty(positions)) {
      getVoxDocumentsData({
        variables: {
          path: 'common',
        },
      });
      getPortfolio({
        variables: {
          personId: `${user?.personId}`,
        },
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPortfolio, getVoxDocumentsData]);
  // When get porfolio data
  React.useEffect(() => {
    if (error) {
      const gqlError = error.graphQLErrors[0];
      const errorCode = gqlError ? gqlError.extensions.code : true;
      if (errorCode && errorCode.toString() === 'UNAUTHENTICATED') {
        trackError(dataDogTags.fetch.errors.unauthentication, { gqlError });
        showAlert({
          title: e('errors.auth.SessionExpired.title'),
          message: e('errors.auth.SessionExpired.message'),
          type: EAlertType.error,
          primaryAlertAction: () => {
            clearSession();
            closeAlert();
          },
        });
      } else {
        trackError(dataDogTags.fetch.errors.graphql, { gqlError });
        showAlert({
          title: c('errors.common.wrong.title'),
          message: c('errors.common.wrong.message'),
          type: EAlertType.error,
        });
      }
    } else if (data && data.portfolio && data.portfolio.financialSummary) {
      const dataPosition: any[] = data.portfolio.positions || [];
      const dataDocuments: any[] = voxData?.documents || [];
      const formatData: any[] =
        formatGraphData(data.portfolio.financialSummary.historyData) || [];
      const cPosition =
        data.portfolio.financialSummary.historyData?.position || [];
      const cDates = data.portfolio.financialSummary.historyData?.date || [];
      // const cImpact: Impact[] = formatImpactData(dataPosition) || [];
      const cDocuments: any[] =
        formatDocumentsData(dataPosition, dataDocuments) || [];
      dispatch({
        type: 'SET_POSITIONS_DATA',
        payload: {
          allPositions: data.portfolio.positions,
        },
      });
      dispatch({
        type: 'SET_DOCUMENTS_DATA',
        payload: {
          allDocuments: dataDocuments || [],
        },
      });
      // TODO: Add documents to list
      setDocuments([...cDocuments]);
      // setHistories(cImpact);
      setProfitabilityValue(
        data.portfolio.financialSummary.totalProfitability || 0
      );
      setProfitability(data.portfolio.financialSummary.profitability);
      setLastPositionDate(
        moment(cDates[cDates.length - 1] || new Date()).format('yyyy-MM-DD')
      );
      setHistoryData(formatData);
      setPositions(dataPosition.filter(pos => pos.fund));
      setCurrentPosition({
        grossValue: transFormCurreny(cPosition[cPosition.length - 1] || 0),
        profitability: `${transFormCurreny(
          data.portfolio.financialSummary.totalProfitability || 0
        )}`,
        totalProfitability: `${transFormNumber(
          data.portfolio.financialSummary.profitability || 0.0
        )}%`,
        positive: (data.portfolio.financialSummary.profitability || 0) >= 0,
      });
    }
    setTimeout(() => setLocalLoad(false), 1000);
    return () => {
      setPositions([]);
      setHistoryData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);
  // Handlers
  const downloadDocument = async (file: string) => {
    if (!file) return;
    setDownloading(true);
    trackAction(dataDogTags.actions.home.portfolio.document, { file });
    const response = await downLoadDocumentFile(file);
    if (response && response.error) {
      trackError(dataDogTags.fetch.errors.printfile, { response });
      showAlert({
        type: EAlertType.error,
        title: d('documents.error.download.title'),
        message: d('documents.error.download.gettingUrl'),
      });
    }
    setDownloading(false);
  };
  // Components
  if (loading || voxLoading || localLoad) return <AppLoadingBlock />;
  if (!data || !data.portfolio || error) {
    return (
      <AppEmptyBlock
        refresh={() =>
          refetch
            ? refetch({
                personId: `${user?.personId}`,
              })
            : {}
        }
      />
    );
  }
  return (
    <div className='flex flex-col w-full items-center' ref={refHome}>
      {downloading && <Loading loadingMessage='Aguarde um momento' />}
      <HomeFinancial
        sensitive={state?.showSensitiveValues!}
        profitability={profitability}
      />
      <VoxLayoutContainer>
        <div className='flex flex-col w-full gap-16' ref={refGraphContainer}>
          {refGraphContainer.current && historyData.length ? (
            <div
              className={`w-full mt-16 ${
                positions.length > 0 ? 'mb-0' : 'mb-16'
              }`}
            >
              <AppHomeGraph data-testid='vox-graph-data'>
                <UI.VoxGraph
                  width={refGraphContainer.current.clientWidth}
                  height={420}
                  cash={
                    state?.showSensitiveValues
                      ? currentPosition.grossValue || `R$ 0,00`
                      : '******,**'
                  }
                  profitability={
                    state?.showSensitiveValues
                      ? `${transFormCurreny(profitabilityValue)}`
                      : '******,**'
                  }
                  date={`${moment(lastPositionDate).format('LL')}`}
                  positive={profitabilityValue > 0}
                  tags={graphTags}
                  graphData={historyData}
                />
              </AppHomeGraph>
            </div>
          ) : (
            <></>
          )}
        </div>
        {positions.length ? (
          <div className='mb-14'>
            <br />
            <HomePositions
              positions={positions}
              sesitive={state?.showSensitiveValues || false}
              refresh={() =>
                refetch
                  ? refetch({
                      personId: `${user?.personId}`,
                    })
                  : {}
              }
            />
          </div>
        ) : (
          <></>
        )}
      </VoxLayoutContainer>
      <HomeDocuments
        documents={documents}
        downloadDocument={downloadDocument}
        showMore={() => history.push('/AppDocuments')}
      />
    </div>
  );
};

export default AppHome;
