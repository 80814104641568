import {
  Colors,
  EFontWeight,
  TextSizes,
  VoxLayoutContainer,
  VoxText,
} from '@vox-capital/vox-ui-package';
import React from 'react';
import { Disclaimer } from '../../../ui';
import AuthContext from '../../../context/AuthContext';
import { useTranslation } from 'react-i18next';

const HomeFinancial: React.FC<{
  sensitive: boolean;
  profitability: number;
}> = ({ sensitive, profitability }) => {
  const { currentPosition } = React.useContext(AuthContext);
  const [t] = useTranslation('home');

  return (
    <div className='bg-gray-200 w-full flex items-center justify-center'>
      <VoxLayoutContainer>
        <div className='financial-data flex flex-col gap-2 justify-start items-start py-16'>
          <div className='flex flex-col md:flex-row-reverse w-full gap-2 flex-1 bg-gray-400 rounded-md items-center'>
            <div
              className='w-full md:w-8/12 h-256 overflow-hidden rounded-r-md'
              style={{
                backgroundPosition: 'top',
                backgroundSize: 'cover',
                backgroundImage:
                  'url("https://frontend-assets-voxri.s3.sa-east-1.amazonaws.com/report/vox-image-og-banner-card.png")',
              }}
            />
            <div className='flex flex-col w-full md:w-4/12 pl-8 pr-4 pt-8 pb-4 gap-4'>
              <div className='pb-2 flex flex-col'>
                <VoxText
                  preset={TextSizes.bodyMd}
                  weight={EFontWeight.bold}
                  color={Colors.pink800}
                >
                  Rentabilidade
                </VoxText>
                <VoxText preset={TextSizes.heading4} weight={EFontWeight.bold}>
                  {t('overview.impact.profitability')}
                  <VoxText
                    preset={TextSizes.heading4}
                    weight={EFontWeight.bold}
                    color={Colors.dark200}
                  >
                    {' '}
                    {sensitive ? currentPosition.totalProfitability : '**,**%'}
                  </VoxText>
                </VoxText>
              </div>
              <div className='flex gap-2 items-center justify-end cursor-pointer'>
                <Disclaimer
                  children={
                    <div className='py-4'>
                      <VoxText
                        preset={TextSizes.bodyMd}
                        weight={EFontWeight.medium}
                        color={Colors.darkBlue}
                      >
                        <b>Rentabilidade:</b> A cota do fundo é impactada
                        negativamente quando há evento de amortização de cotas.
                        Dessa forma, nos casos em que houve amortização, a
                        rentabilidade demonstrada neste relatório é gerencial,
                        uma vez que ela considera os ganhos auferidos com as
                        amortizações já pagas.
                      </VoxText>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </VoxLayoutContainer>
    </div>
  );
};

export default HomeFinancial;
