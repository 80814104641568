// Component generated with util/vox-create-component.js
import React from 'react';
import { motion } from 'framer-motion';
import * as R from 'ramda';
import { LegacyWrapper } from './Legacy.styled';
import { LegacyProps } from './Legacy.types';
import { EAlertType } from '@vox-capital/vox-ui-package';
import { getImageDownloadLink } from '../../service/api';
import { disableScroll, enableScroll } from '../../helpers';
import { client } from './../../service/vimeo';
import { AppImpactDetail } from '../../component';
import AlertContext from '../../context/AlertContext';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader/Loader';
import { dataDogTags, trackAction } from '../../analytics';

export const Legacy: React.FC<LegacyProps> = ({ kaleidoscope, ...args }) => {
  // States
  const [imagesUrls, setImagesUrls] = React.useState([]);
  const [videosUrls, setVideosUrls] = React.useState([]);
  const [imageUrl, setImageUrl] = React.useState<string>('');
  const [showDetail, setShowDetail] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [dataLoaded, seteDataLoaded] = React.useState<boolean>(false);
  // Context
  const { showAlert, closeAlert } = React.useContext(AlertContext);

  // Translations
  const [c] = useTranslation('common');

  // Effects
  React.useEffect(() => {
    if (kaleidoscope.imageId?.length && kaleidoscope.imageId[0]) {
      setLoading(true);
      // TODO: Datadog, add track here
      const firstImage = kaleidoscope.imageId[0];
      (async () => {
        const imageDownloadedUrl = await getImageDownloadLink(firstImage);
        setImageUrl(imageDownloadedUrl.fileUrl);
      })().finally(() => {
        setLoading(false);
      });
    }
  }, [kaleidoscope.imageId]);

  // Void
  const toggleDetail = async () => {
    trackAction(dataDogTags.actions.legacy.impact, {
      kaleidoscope,
    });
    if (dataLoaded) {
      setShowDetail(true);
      return;
    }
    disableScroll();
    try {
      setLoading(true);
      const allImages: any[] = [
        ...(kaleidoscope.imageId || []).filter(img => img),
      ];
      const allVideos: any[] = [
        ...(kaleidoscope.videoId || []).filter(vid => vid),
      ];
      if (!R.isEmpty(allVideos)) {
        const getVideosUrls = await Promise.all([
          ...allVideos.reduce(
            (acc: any, next: any) => [
              ...acc,
              new Promise(resolve => {
                client.request(
                  `/videos/${R.last(R.split('/', next))}`,
                  (error, body, status, headers) => {
                    if (error) resolve('');
                    if (body) resolve(body);
                  }
                );
              }),
            ],
            []
          ),
        ]);
        setVideosUrls(
          getVideosUrls
            .filter((vid: any) => vid)
            .reduce(
              (acc: any, next: any) => [...acc, next.player_embed_url],
              []
            )
        );
      }
      if (!R.isEmpty(allImages)) {
        const getImagesUrls = await Promise.all([
          ...allImages.reduce(
            (acc: any, next: any) => [
              ...acc,
              new Promise(resolve => {
                resolve(getImageDownloadLink(next));
              }),
            ],
            []
          ),
        ]);
        setImagesUrls(
          getImagesUrls.reduce(
            (acc: any, next: any) => [...acc, next.fileUrl],
            []
          )
        );
      }
      setShowDetail(true);
    } catch (error) {
      showAlert({
        title: c('errors.common.default.title'),
        message: c('errors.common.default.message'),
        type: EAlertType.error,
        primaryAlertAction: () => closeAlert(),
      });
    } finally {
      setLoading(false);
      seteDataLoaded(true);
    }
  };

  // Dom
  return (
    <LegacyWrapper {...args}>
      {showDetail && (
        <AppImpactDetail
          impactData={{
            ...kaleidoscope,
            imageId: [...imagesUrls],
            videoId: [...videosUrls],
          }}
          onCloseDetail={() => {
            setShowDetail(false);
            enableScroll();
          }}
        />
      )}
      <div
        className='pb-full rounded-sm relative border-2 border-gray-200 hover:border-pink-800'
        onClick={toggleDetail}
      >
        <motion.div
          initial={{
            opacity: 0,
            backgroundImage: '',
          }}
          animate={{
            opacity: 1,
            backgroundImage: `url(${imageUrl})`,
          }}
          transition={{
            duration: 0.4,
            easings: 'easeInOut',
          }}
          className='absolute bg-gray-200 w-full h-full flex items-end'
          style={{
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          {loading && (
            <div className='absolute left-0 right-0 top-0 bottom-0 bg-darkBlue-300 bg-opacity-30 flex items-center justify-center'>
              <Loader message='' />
            </div>
          )}
          <div
            className='bg-light-100 border-t-2 border-gray-200 py-2 px-4 w-full flex items-center'
            style={{ minHeight: 72 }}
          >
            <span className='text-sm font-bold'>{kaleidoscope.title}</span>
          </div>
        </motion.div>
      </div>
    </LegacyWrapper>
  );
};

export default Legacy;
