// Component generated with util/vox-create-component.js
import React from 'react';
import { QuoteWrapper } from './Quote.styled';
import { QuoteProps } from './Quote.types';
import {
  Colors,
  EFontFamily,
  EFontWeight,
  TextSizes,
  VoxText,
} from '@vox-capital/vox-ui-package';

export const Quote: React.FC<QuoteProps> = ({
  title,
  message,
  owner,
  role,
  ...args
}) => {
  return (
    <QuoteWrapper {...args}>
      <div id='legacy-presentation-quote'>
        <div className='relative pl-6 pb-6 pt-8 pr-16 rounded-sm bg-gray-200 '>
          <div className='absolute -left-4 -top-8'>
            <span className='text-8xl font-bold text-pink-900 font-serif'>
              “
            </span>
          </div>
          <div className='header mb-4'>
            <span className='text-lg font-bold text-darkBlue-300'>{title}</span>
          </div>
          <div className='content mb-8'>
            <span className='font-bold text-pink-900'>{message}</span>
          </div>
          <div className='footer px-4 flex flex-col'>
            <span className='font-bold text-gray-500'>{owner}</span>
            <span className='text-sm font-bold text-gray-500'>{role}</span>
          </div>
        </div>
      </div>
    </QuoteWrapper>
  );
};

export default Quote;
